import React, { useState, useEffect } from "react"
import { createAuthProvider } from "react-token-auth"
import jwt_decode from "jwt-decode"

export const localStorageKey = "JUKEBOOKS_TOKEN_AUTH"

export const [useAuth, authFetch, login, logout] = createAuthProvider({
    accessTokenKey: "access_token",
    localStorageKey: localStorageKey,
    onUpdateToken: token =>
        fetch("/api/refresh", {
            method: "POST",
            headers: { Authorization: `Bearer ${token.refresh_token}` }
        })
            .then(r => {
                if (r.status === 401) {
                    logout()
                    //return Promise.reject(r)
                    return null
                }
                return r.json()
            })
            .then(data => ({ ...data, refresh_token: token.refresh_token }))
})

export const AuthContext = React.createContext()

export function AuthContextProvider({ children }) {
    const [userInfo, setUserInfo] = useState(null)

    const invalidateUserInfo = () => {
        const raw_data = window.localStorage.getItem(localStorageKey)
        try {
            const token = JSON.parse(raw_data).access_token
            const jwt_payload = jwt_decode(token)
            const claims = jwt_payload.claims
            const roles = new Set(claims.roles.split(",").filter(i => i))
            setUserInfo({
                username: claims.username,
                roles: roles,
                is_contrib: roles.has("contrib") || roles.has("admin"),
                is_guest: roles.size === 0
            })
        } catch (error) {
            setUserInfo(null)
            logout()
        }
    }

    useEffect(() => {
        invalidateUserInfo()
    }, [])

    const defaultContext = {
        userInfo,
        setUserInfo,
        invalidateUserInfo
    }

    return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>
}
