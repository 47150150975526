import React, { useRef, useState } from "react"

const Demande = () => {
    const [formData, setFormData] = useState({
        nom: "",
        email: "",
        body: ""
    })
    const form = useRef(null)
    const [message, setMessage] = useState("")
    const [error, setError] = useState(false)
    const [done, setDone] = useState(false)

    const handleChange = evt => {
        setFormData({ ...formData, [evt.target.name]: evt.target.value })
    }

    const handleSubmit = evt => {
        evt.preventDefault()
        setError(false)
        setMessage("")
        setDone(false)
        fetch("/api/mailing/message", { method: "POST", body: JSON.stringify(formData) })
            .then(r => r.json())
            .then(data => {
                if (data.ok) {
                    setMessage("Votre message a été transmis.")
                    setDone(true)
                } else {
                    setError(true)
                    setMessage(data.msg)
                }
            })
    }

    return (
        <>
            <h3 className="subtitle">Demander un livre</h3>
            {message && <div className={`notification ${error && "is-danger"}`}>{message}</div>}
            {!done && (
                <form onSubmit={handleSubmit} ref={form}>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label" htmlFor="nom">
                                Votre nom
                            </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <input
                                        className="input"
                                        type="text"
                                        id="nom"
                                        name="nom"
                                        value={formData.nom || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label" htmlFor="email">
                                Votre adresse e-mail
                            </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <input
                                        className="input"
                                        type="text"
                                        id="email"
                                        name="email"
                                        value={formData.email || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label" htmlFor="body">
                                Votre message
                            </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        id="body"
                                        name="body"
                                        rows="6"
                                        onChange={handleChange}
                                        value={formData.body || ""}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal"></div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <input type="submit" value="Envoyer" className="button is-primary" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </>
    )
}

export default Demande
