import React, { useEffect, useContext } from "react"
import { Redirect } from "react-router"
import { AuthContext, logout } from "./index"
import { PlayerContext } from "../PlayerContext"

export default function Logout() {
    const authContext = useContext(AuthContext)
    const playerContext = useContext(PlayerContext)

    useEffect(() => {
        playerContext.clear()
        logout()
        authContext.invalidateUserInfo()
    }, [authContext, playerContext])

    return <Redirect to="/" push={true} />
}
