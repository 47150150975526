import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import logo from "./graphix/logo.svg"

const Home = () => {
    const [online, setOnline] = useState()

    useEffect(() => {
        fetch("/api")
            .then(r => r.json())
            .then(data => setOnline(true))
            .catch(err => setOnline(false))
    }, [])

    return online ? (
        <Redirect to="/catalog" />
    ) : (
        <div className="has-text-centered">
            <div className="hero-body">
                <img src={logo} style={{ height: "40vmin", pointerEvents: "none" }} alt="Juke-Books" />
                <br />
                <div className="notification">Service hors-ligne.</div>
            </div>
        </div>
    )
}

export default Home
