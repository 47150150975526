import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import queryString from "query-string"
import ReactPaginate from "react-paginate"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons"
import { AuthContext } from "../auth"
import EntryList from "../lib/EntryList"
import auhasard from "../graphix/auhasard.svg"

const Catalog = () => {
    const authContext = useContext(AuthContext)
    const [items, setItems] = useState([])
    const location = useLocation()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [meta, setMeta] = useState({
        formats: [],
        genres: [],
        editeurs: []
    })
    const [format, setFormat] = useState("0")
    const [genre, setGenre] = useState("0")
    const [editeur, setEditeur] = useState("")

    useEffect(() => {
        const parsed = queryString.parse(location.search)
        if (!parsed.p) parsed.p = 1
        if (parseInt(parsed.f)) setFormat(parsed.f)
        else {
            delete parsed.f
            setFormat("0")
        }
        if (parseInt(parsed.g)) setGenre(parsed.g)
        else {
            delete parsed.g
            setGenre("0")
        }
        if (parsed.e) setEditeur(parsed.e)
        fetch("/api/catalog/meta")
            .then(r => r.json())
            .then(data => {
                if (data) {
                    setMeta(data)
                }
            })
        fetch("/api/catalog/list?" + queryString.stringify(parsed))
            .then(r => r.json())
            .then(data => {
                if (data) {
                    setPage(data.page)
                    setPageCount(Math.ceil(data.total / data.perPage))
                    setItems(data.items)
                }
            })
    }, [location.search, format, genre, editeur])

    const handleChangeFormat = evt => {
        const f = evt.target.value
        const parsed = queryString.parse(location.search)
        delete parsed.p
        if (parseInt(f)) parsed.f = f
        else delete parsed.f
        history.push("/catalog?" + queryString.stringify(parsed))
    }

    const handleChangeGenre = evt => {
        const g = evt.target.value
        const parsed = queryString.parse(location.search)
        delete parsed.p
        if (parseInt(g)) parsed.g = g
        else delete parsed.g
        history.push("/catalog?" + queryString.stringify(parsed))
    }

    const handleChangeEditeur = evt => {
        const e = evt.target.value
        const parsed = queryString.parse(location.search)
        delete parsed.p
        if (e) parsed.e = e
        else {
            delete parsed.e
            setEditeur("")
        }
        history.push("/catalog?" + queryString.stringify(parsed))
    }

    const hrefBuild = p => {
        const param = {}
        if (parseInt(format)) param.f = format
        if (parseInt(genre)) param.g = genre
        if (editeur) param.e = editeur
        return "/catalog?" + queryString.stringify({ p: p, ...param })
    }

    return (
        <div>
            <div className="level">
                <div className="level-left">
                    <h3 className="subtitle" style={{ marginBottom: "0" }}>
                        Catalogue
                    </h3>
                    &nbsp;
                    <nav className="pagination is-small">
                        <ReactPaginate
                            onPageChange={p => history.push(hrefBuild(p.selected + 1))}
                            hrefBuilder={p => hrefBuild(p)}
                            initialPage={page - 1}
                            forcePage={page - 1}
                            disableInitialCallback={true}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            previousLabel="<"
                            nextLabel=">"
                            containerClassName="pagination-list"
                            pageLinkClassName="pagination-link"
                            previousLinkClassName="pagination-link"
                            nextLinkClassName="pagination-link"
                            activeLinkClassName="pagination-link is-current"
                            breakLinkClassName="pagination-ellipsis"
                        />
                    </nav>
                </div>
                <div className="level-item">
                    {authContext.userInfo?.is_contrib ? (
                        <div className="has-text-centered">
                            <Link to="/catalog/create" className="button">
                                <FontAwesomeIcon icon={faPlusSquare} />
                                &nbsp; Ajouter un livre
                            </Link>
                        </div>
                    ) : (
                        <div className="has-text-centered">
                            <Link to="/catalog/random" className="navbar-item">
                                <img src={auhasard} alt="" /> &nbsp; <span className="is-size-4">Livre au hasard</span>
                            </Link>
                        </div>
                    )}
                </div>
                <div className="level-right">
                    <div className="columns">
                        <div className="column">
                            <div className="field is-horizontal">
                                <div className="field-label is-normal" style={{ marginRight: "0.5rem" }}>
                                    <label className="label" htmlFor="f">
                                        Format
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <select
                                                className="select"
                                                id="f"
                                                name="f"
                                                value={format || "0"}
                                                onChange={handleChangeFormat}>
                                                <option value="0"></option>
                                                {meta.formats.map(item => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.libelle}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field is-horizontal">
                                <div className="field-label is-normal" style={{ marginRight: "0.5rem" }}>
                                    <label className="label" htmlFor="g">
                                        Genre
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <select
                                                className="select"
                                                id="g"
                                                name="g"
                                                value={genre || "0"}
                                                onChange={handleChangeGenre}>
                                                <option value="0"></option>
                                                {meta.genres.map(item => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.libelle}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field is-horizontal">
                                <div className="field-label is-normal" style={{ marginRight: "0.5rem" }}>
                                    <label className="label" htmlFor="e">
                                        Éditeur
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <select
                                                className="select"
                                                id="e"
                                                name="e"
                                                value={editeur || ""}
                                                onChange={handleChangeEditeur}>
                                                <option value=""></option>
                                                {meta.editeurs.map(item => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EntryList items={items} />
        </div>
    )
}

export default Catalog
