import React, { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEject, faPause, faPlay } from "@fortawesome/free-solid-svg-icons"
import { formatDuration } from "./lib/utils"

export const PlayerContext = React.createContext()

export function PlayerContextProvider({ children }) {
    const [playing, setPlaying] = useState(false)
    const [track, setTrack] = useState(null)
    const [currentTime, setCurrentTime] = useState(null)
    const audio = useRef()

    useEffect(() => {
        if ("mediaSession" in navigator) {
            navigator.mediaSession.setActionHandler("play", resume)
            navigator.mediaSession.setActionHandler("pause", pause)
            navigator.mediaSession.setActionHandler("stop", clear)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const playTrack = data => {
        setTrack(data)
        setPlaying(false)
        if ("mediaSession" in navigator) {
            navigator.mediaSession.metadata = new window.MediaMetadata({
                title: data.titre,
                artist: data.auteur,
                album: "",
                artwork: [{ src: data.artwork ? data.artwork : "blank.jpg", sizes: "128x128", type: "image/jpeg" }]
            })
        }
        audio.current.src = data.audio
        const playPromise = audio.current.play()
        if (playPromise !== undefined) {
            playPromise
                .then(_ => {
                    setPlaying(true)
                })
                .catch(error => {})
        }
    }

    const pause = () => {
        setPlaying(false)
        if (audio.current) audio.current.pause()
        if (navigator.mediaSession) navigator.mediaSession.playbackState = "paused"
    }

    const resume = async () => {
        await audio.current.play()
        setPlaying(true)
        if (navigator.mediaSession) navigator.mediaSession.playbackState = "playing"
    }

    const clear = () => {
        pause()
        setTrack(null)
        if ("mediaSession" in navigator) {
            navigator.mediaSession.metadata = new window.MediaMetadata()
            navigator.mediaSession.playbackState = "none"
        }
    }

    const defaultContext = {
        playTrack,
        track,
        playing,
        pause,
        resume,
        clear
    }

    return (
        <PlayerContext.Provider value={defaultContext}>
            {children}
            <audio ref={audio} onTimeUpdate={e => setCurrentTime(e.target.currentTime)} />
            {track && (
                <section className="section player">
                    <div className="container">
                        <div className="level">
                            <div className="level-left">
                                {playing ? (
                                    <button className="button" onClick={pause}>
                                        <FontAwesomeIcon icon={faPause} />
                                    </button>
                                ) : (
                                    <button className="button" onClick={resume}>
                                        <FontAwesomeIcon icon={faPlay} />
                                    </button>
                                )}
                            </div>
                            <div className="level-item">
                                <div className="has-text-centered" style={{ width: "50%" }}>
                                    {track.auteur} - {track.titre}
                                    <br />
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <span className="is-size-7">{formatDuration(currentTime)}</span>
                                                </td>
                                                <td>
                                                    <input
                                                        className="slider is-fullwidth is-small is-circle"
                                                        step="1"
                                                        min="0"
                                                        max={track.duration}
                                                        value={currentTime || 0}
                                                        onChange={e => {
                                                            audio.current.currentTime = e.target.value
                                                        }}
                                                        type="range"
                                                    />
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <span className="is-size-7">{formatDuration(track.duration)}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="level-right">
                                <button className="button" onClick={clear}>
                                    <FontAwesomeIcon icon={faEject} />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </PlayerContext.Provider>
    )
}
