import React from "react"
import { useHistory } from "react-router-dom"

const EntryList = ({ items }) => {
    const history = useHistory()

    return items && items.length ? (
        <div className="entry-list">
            {items.map(item => (
                <article
                    key={item.id}
                    onClick={() => history.push(`/catalog/item/${item.id}`)}
                    className="box entry-list-item"
                    style={{ cursor: "pointer", margin: "0 0.5em 0.5em 0", width: "32%", alignItems: "flex-end" }}>
                    <figure className="">
                        <p className="image is-128x128">{item.thumbnail && <img src={item.thumbnail} alt="" />}</p>
                    </figure>
                    <div className="content">
                        <span className="is-size-5 has-text-weight-bold">{item.titre}</span>
                        <br />
                        <span className="is-size-6">{item.auteur}</span>
                        <br />
                        <span className="is-size-7">{[item.format, item.genre].filter(i => i).join(" | ")}</span>
                    </div>
                </article>
            ))}
        </div>
    ) : null
}

export default EntryList
