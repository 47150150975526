import React from "react"
import { Link, matchPath, Redirect, Route, Router, Switch, withRouter } from "react-router-dom"
import loadable from "@loadable/component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons"
import { PlayerContext, PlayerContextProvider } from "./PlayerContext"
import { useAuth, AuthContext, AuthContextProvider } from "./auth"
import history from "./history"
import "./all.scss"
import logoTitle from "./graphix/logo-title.svg"
import demander from "./graphix/demander.svg"
import recommandations from "./graphix/recommandations.svg"
import nouveautes from "./graphix/nouveautes.svg"

import Home from "./Home"
import Login from "./auth/Login"
import Logout from "./auth/Logout"
import Downgrade from "./auth/Downgrade"
import TokenInfo from "./auth/TokenInfo"
import Catalog from "./user/Catalog"
import Random from "./user/Random"
import Nouveautes from "./user/Nouveautes"
import Entry from "./user/Entry"
import Recommandations from "./user/Recommandations"
import Demande from "./user/Demande"

const Password = loadable(() => import(/* webpackChunkName: "user" */ "./user/Password"))

const EntryEdit = loadable(() => import(/* webpackChunkName: "contrib" */ "./contrib/EntryEdit"))

const AccountList = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/AccountList"))
const AccountEdit = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/AccountEdit"))
const Dashboard = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/Dashboard"))
const Genre = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/Genre"))
const Format = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/Format"))
const Mailing = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/Mailing"))

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [logged] = useAuth()

    return (
        <Route
            {...rest}
            render={props => {
                if (logged) return <Component {...props} />
                else return <Redirect to={"/login?next=" + encodeURIComponent(props.location.pathname)} />
            }}
        />
    )
}

const Navigation = withRouter(({ location }) => {
    const m = matchPath(location.pathname, { path: "/retrieve/:code", exact: true })
    const noNav = m || ["/", "/login", "/recover"].includes(location.pathname)
    return (
        !noNav && (
            <AuthContext.Consumer>
                {({ userInfo }) => (
                    <div className="container">
                        <nav className="navbar" role="navigation" aria-label="navigation">
                            <div className="navbar-menu">
                                <div className="navbar-end">
                                    {userInfo ? (
                                        <div className="navbar-end">
                                            {!userInfo.is_guest && (
                                                <div className="navbar-item has-dropdown is-hoverable">
                                                    <span className="navbar-link">
                                                        <FontAwesomeIcon icon={faUser} /> &nbsp; {userInfo.username}
                                                    </span>
                                                    <div className="navbar-dropdown">
                                                        {userInfo.roles.has("admin") && (
                                                            <Link to="/dashboard" className="navbar-item">
                                                                Administration
                                                            </Link>
                                                        )}
                                                        {!userInfo.is_guest && (
                                                            <Link to="/password" className="navbar-item">
                                                                Mot de passe
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <Link to="/logout" className="navbar-item">
                                                <FontAwesomeIcon icon={faPowerOff} /> &nbsp; Déconnexion
                                            </Link>
                                        </div>
                                    ) : (
                                        <div className="navbar-end">
                                            <Link to="/login" className="navbar-item">
                                                <FontAwesomeIcon icon={faUser} /> &nbsp; Connexion
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </nav>
                        <div className="level">
                            <div className="level-item">
                                <Link to="/catalog">
                                    <img src={logoTitle} style={{ height: "90px" }} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="level">
                            <div className="level-item">
                                <nav className="navbar" role="navigation" aria-label="main navigation">
                                    <Link to="/nouveautes" className="navbar-item">
                                        <img src={nouveautes} alt="" /> &nbsp;
                                        <span className="is-size-4">Nouveautés</span>
                                    </Link>
                                    <Link to="/recommandations" className="navbar-item">
                                        <img src={recommandations} alt="" /> &nbsp;
                                        <span className="is-size-4">Recommandations</span>
                                    </Link>
                                    <Link to="/demande" className="navbar-item">
                                        <img src={demander} alt="" /> &nbsp;
                                        <span className="is-size-4">Demander un livre</span>
                                    </Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                )}
            </AuthContext.Consumer>
        )
    )
})

function App() {
    return (
        <Router history={history}>
            <PlayerContextProvider>
                <AuthContextProvider>
                    <Navigation />
                    <PlayerContext.Consumer>
                        {() => (
                            <section className="section main-content">
                                <div className="container">
                                    <Switch>
                                        <Route exact path="/" component={Home} />
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/logout" component={Logout} />
                                        <Route exact path="/downgrade" component={Downgrade} />
                                        <Route exact path="/token-info" component={TokenInfo} />
                                        <Route exact path="/catalog" component={Catalog} />
                                        <Route exact path="/catalog/random" component={Random} />
                                        <Route exact path="/nouveautes" component={Nouveautes} />
                                        <Route exact path="/recommandations" component={Recommandations} />
                                        <Route exact path="/demande" component={Demande} />
                                        <Route exact path="/catalog/item/:id" component={Entry} />
                                        <PrivateRoute exact path="/catalog/create" component={EntryEdit} />
                                        <PrivateRoute exact path="/catalog/edit/:id" component={EntryEdit} />
                                        <PrivateRoute exact path="/password" component={Password} />
                                        <PrivateRoute exact path="/account/list" component={AccountList} />
                                        <PrivateRoute exact path="/account/create" component={AccountEdit} />
                                        <PrivateRoute exact path="/account/:id" component={AccountEdit} />
                                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                        <PrivateRoute exact path="/genre" component={Genre} />
                                        <PrivateRoute exact path="/format" component={Format} />
                                        <PrivateRoute exact path="/mailing" component={Mailing} />
                                        <Route path="*" render={() => <h2 className="title">Not found</h2>} />
                                    </Switch>
                                </div>
                            </section>
                        )}
                    </PlayerContext.Consumer>
                </AuthContextProvider>
            </PlayerContextProvider>
        </Router>
    )
}

export default App
