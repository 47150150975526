import React, { useEffect, useState } from "react"
import EntryList from "../lib/EntryList"

const Recommandations = () => {
    const [items, setItems] = useState([])

    useEffect(() => {
        fetch("/api/catalog/recommandations")
            .then(r => r.json())
            .then(data => {
                if (data.items) {
                    setItems(data.items)
                }
            })
    }, [])

    return (
        <div>
            <h3 className="subtitle">Recommandations</h3>
            <EntryList items={items} />
        </div>
    )
}

export default Recommandations
