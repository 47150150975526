import { useEffect, useContext } from "react"
import { authFetch, AuthContext, login } from "./index"
import history from "../history"

export default function Downgrade() {
    const authContext = useContext(AuthContext)

    useEffect(() => {
        authFetch("/api/downgrade")
            .then(r => r.json())
            .then(data => {
                if (data.access_token) {
                    login(data)
                    authContext.invalidateUserInfo()
                    history.push("/catalog")
                }
            })
        // eslint-disable-next-line
    }, [])

    return null
}
