import React, { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash, faPause, faPlay } from "@fortawesome/free-solid-svg-icons"
import { AuthContext, authFetch } from "../auth"
import { PlayerContext } from "../PlayerContext"
import retour from "../graphix/retour.svg"
import history from "../history"

const Entry = () => {
    const authContext = useContext(AuthContext)
    const playerContext = useContext(PlayerContext)
    const { id } = useParams()
    const [data, setData] = useState({})

    useEffect(() => {
        fetch(`/api/catalog/item/${id}`)
            .then(r => r.json())
            .then(data => {
                data.meta = [data.format, data.genre].filter(i => i).join(" | ")
                data.editisbn = [data.editeur, data.isbn].filter(i => i).join(" - ")
                setData(data)
            })
    }, [id])

    const play = () => {
        playerContext.playTrack(data)
    }

    const handleDelete = e => {
        if (window.confirm("Confirmez la suppression du livre")) {
            if (playerContext.track && id === playerContext.track.id) playerContext.clear()
            authFetch(`/api/catalog/edit/${id}`, { method: "DELETE" })
                .then(r => r.json())
                .then(data => {
                    history.push("/catalog")
                })
        }
    }

    return (
        <>
            <div className="has-text-left">
                {authContext.userInfo?.is_contrib ? (
                    <>
                        <Link to={`/catalog/edit/${id}`} className="button is-link is-small">
                            <FontAwesomeIcon icon={faEdit} /> &nbsp; Éditer
                        </Link>{" "}
                        <a href="#/" onClick={handleDelete} className="button is-danger is-small">
                            <FontAwesomeIcon icon={faTrash} /> &nbsp; Supprimer
                        </a>
                    </>
                ) : (
                    <a href="#/" className="navbar-item" onClick={() => history.goBack()}>
                        <img src={retour} alt="" /> &nbsp; Retour
                    </a>
                )}
                <br />
                <br />
            </div>
            <div className="columns">
                <div className="column has-text-centered">{data.artwork && <img src={data.artwork} alt="" />}</div>
                <div className="column">
                    {data.titre && <div className="title">{data.titre}</div>}
                    {data.auteur && <div className="subtitle">{data.auteur}</div>}
                    {data.meta && (
                        <p>
                            {data.meta}
                            <br />
                        </p>
                    )}
                    {data.editisbn && (
                        <p className="is-size-7">
                            {data.editisbn}
                            <br />
                        </p>
                    )}
                    <br />
                    {data.audio && (
                        <>
                            <br />
                            {playerContext.track && playerContext.track.id === data.id ? (
                                playerContext.playing ? (
                                    <div onClick={playerContext.pause} style={{ cursor: "pointer" }}>
                                        <FontAwesomeIcon icon={faPause} size="2x" />
                                    </div>
                                ) : (
                                    <div onClick={playerContext.resume} style={{ cursor: "pointer" }}>
                                        <FontAwesomeIcon icon={faPlay} size="2x" />
                                    </div>
                                )
                            ) : (
                                <div onClick={play} style={{ cursor: "pointer" }}>
                                    <FontAwesomeIcon icon={faPlay} size="2x" />
                                </div>
                            )}
                            <br />
                        </>
                    )}
                    {data.resume && <div dangerouslySetInnerHTML={{ __html: data.resume }}></div>}
                </div>
            </div>
        </>
    )
}

export default Entry
